import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import {
  isSignupEmailTakenError,
  isTooManyEmailVerificationRequestsError,
} from '../../../util/errors';

import { FormattedMessage, injectIntl, intlShape } from '../../../util/reactIntl';
import * as validators from '../../../util/validators';
import { Form, PrimaryButton, FieldTextInput, FieldRadioButton } from '../../../components';
import PhoneInput from 'react-phone-input-2';
import './PhoneInput2.css';

import css from './SignupForm.module.css';

const KEY_CODE_ENTER = 13;

const SignupFormComponent = (props) => (
  <FinalForm
    {...props}
    render={(fieldRenderProps) => {
      const {
        rootClassName,
        className,
        formId,
        form,
        handleSubmit,
        inProgress,
        invalid,
        intl,
        values,
        initialValues,
        onOpenTermsOfService,
        touched,
        errors,
      } = fieldRenderProps;
      const [phnErr, setPhnErr] = useState(false);
      const [phnInfo, setPhnInfo] = useState(false);

      // email
      const emailLabel = intl.formatMessage({
        id: 'SignupForm.emailLabel',
      });
      const emailPlaceholder = intl.formatMessage({
        id: 'SignupForm.emailPlaceholder',
      });
      const emailRequiredMessage = intl.formatMessage({
        id: 'SignupForm.emailRequired',
      });
      const emailRequired = validators.required(emailRequiredMessage);
      const emailInvalidMessage = intl.formatMessage({
        id: 'SignupForm.emailInvalid',
      });
      const emailValid = validators.emailFormatValid(emailInvalidMessage);
      const phoneLabel = intl.formatMessage({ id: 'SignupForm.phoneLabel' });

      // password
      const passwordLabel = intl.formatMessage({
        id: 'SignupForm.passwordLabel',
      });
      const passwordPlaceholder = intl.formatMessage({
        id: 'SignupForm.passwordPlaceholder',
      });
      const passwordRequiredMessage = intl.formatMessage({
        id: 'SignupForm.passwordRequired',
      });
      const passwordMinLengthMessage = intl.formatMessage(
        {
          id: 'SignupForm.passwordTooShort',
        },
        {
          minLength: validators.PASSWORD_MIN_LENGTH,
        }
      );
      const passwordMaxLengthMessage = intl.formatMessage(
        {
          id: 'SignupForm.passwordTooLong',
        },
        {
          maxLength: validators.PASSWORD_MAX_LENGTH,
        }
      );
      //Use atleast one special character, a captilat letter, small letter and number.

      const strongPasswordMessage = intl.formatMessage({
        id: 'SignupForm.strongPasswordRequired',
      });

      const passwordMinLength = validators.minLength(
        passwordMinLengthMessage,
        validators.PASSWORD_MIN_LENGTH
      );
      const passwordMaxLength = validators.maxLength(
        passwordMaxLengthMessage,
        validators.PASSWORD_MAX_LENGTH
      );

      const strongPassword = validators.strongPassword(strongPasswordMessage);

      const passwordRequired = validators.requiredStringNoTrim(passwordRequiredMessage);

      const passwordValidators = validators.composeValidators(
        passwordRequired,
        passwordMinLength,
        passwordMaxLength,
        strongPassword
      );

      // firstName
      const firstNameLabel = intl.formatMessage({
        id: 'SignupForm.firstNameLabel',
      });
      const firstNamePlaceholder = intl.formatMessage({
        id: 'SignupForm.firstNamePlaceholder',
      });
      const firstNameRequiredMessage = intl.formatMessage({
        id: 'SignupForm.firstNameRequired',
      });
      const checkBoxRequiredMessage = intl.formatMessage({
        id: 'SignupForm.checkBoxRequiredMessage',
      });
      const firstNameRequired = validators.required(firstNameRequiredMessage);
      const fNameRegexErrorMsg = intl.formatMessage({
        id: 'SignupForm.fNameRegexErrorMsg',
      });
      const fNameRegexValidator = validators.validatenameRegex(fNameRegexErrorMsg);
      const fNameValidators = validators.composeValidators(firstNameRequired, fNameRegexValidator);

      const checkBoxRequired = validators.required(checkBoxRequiredMessage);
      // lastName
      const lastNameLabel = intl.formatMessage({
        id: 'SignupForm.lastNameLabel',
      });
      const lastNamePlaceholder = intl.formatMessage({
        id: 'SignupForm.lastNamePlaceholder',
      });
      const lastNameRequiredMessage = intl.formatMessage({
        id: 'SignupForm.lastNameRequired',
      });
      const lastNameRequired = validators.required(lastNameRequiredMessage);
      const lNameRegexErrorMsg = intl.formatMessage({
        id: 'SignupForm.lNameRegexErrorMsg',
      });
      const lNameRegexValidator = validators.validatenameRegex(lNameRegexErrorMsg);
      const lNameValidators = validators.composeValidators(lastNameRequired, lNameRegexValidator);

      const classes = classNames(rootClassName || css.root, className);
      const submitInProgress = inProgress;
      const submitDisabled =
        invalid || submitInProgress || !values.phoneNumber || values.phoneNumber === '+';
      const handleTermsKeyUp = (e) => {
        // Allow click action with keyboard like with normal links
        if (e.keyCode === KEY_CODE_ENTER) {
          onOpenTermsOfService();
        }
      };
      const termsLink = (
        <span
          className={css.termsLink}
          onClick={onOpenTermsOfService}
          role="button"
          tabIndex="0"
          onKeyUp={handleTermsKeyUp}
        >
          <FormattedMessage id="SignupForm.termsAndConditionsLinkText" />
        </span>
      );
      const signupErrorMessage = (
        <div className={css.error}>
          {isSignupEmailTakenError(props?.signupError) ? (
            <FormattedMessage id="AuthenticationPage.signupFailedEmailAlreadyTaken" />
          ) : (
            <FormattedMessage id="AuthenticationPage.signupFailed" />
          )}
        </div>
      );
      const Errorclass = props.loginOrSignupError !== null ? true : false;
      const isFieldInvalid = (fieldName) => touched[fieldName] && !!errors[fieldName];

      // const loginOrSignupError =
      // !props?.isLogin && props.errorMessage(props?.signupError, signupErrorMessage);

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          <div>
            <div className={css.radioButton}>
              <div className={css.buttonInput}>
                <span className={css.radioLabel}>I am an</span>
                <FieldRadioButton
                  id="investor"
                  name="entrepreneur"
                  label="Investor"
                  value={false}
                  className={css.entrepreneur}
                  onChange={() => {
                    form.change('entrepreneur', false);
                  }}
                  validate={checkBoxRequired}
                />
              </div>
              <div className={css.buttonInput}>
                <span className={css.radioLabel}>I am an</span>
                <FieldRadioButton
                  id="entrepreneur"
                  name="entrepreneur"
                  label="Entrepreneur"
                  className={css.entrepreneur}
                  value={true}
                  onChange={() => {
                    form.change('entrepreneur', true);
                  }}
                  validate={checkBoxRequired}
                />
              </div>
            </div>
            <div className={css.name}>
              <FieldTextInput
                // className={css.nameRoot}
                className={classNames(css.nameRoot, {
                  [css.invalidName]: isFieldInvalid('fname'),
                })}
                type="text"
                id={formId ? `${formId}.fname` : 'fname'}
                name="fname"
                autoComplete="given-name"
                label={firstNameLabel}
                placeholder={firstNamePlaceholder}
                // validate={firstNameRequired}
                validate={fNameValidators}
              />
              <FieldTextInput
                className={classNames(css.nameRoot, { [css.invalidName]: isFieldInvalid('lname') })}
                type="text"
                id={formId ? `${formId}.lname` : 'lname'}
                name="lname"
                autoComplete="family-name"
                label={lastNameLabel}
                placeholder={lastNamePlaceholder}
                // validate={lastNameRequired}
                validate={lNameValidators}
              />
            </div>
            <div onClick={() => setPhnInfo(true)} className={css.PhoneRoot}>
              <div className={css.contactInfo}>
                <span>Contact Number</span>
                <PhoneInput
                  value={values.phoneNumber}
                  onChange={(val) => {
                    setPhnInfo(false);

                    values.phoneNumber && values.phoneNumber.length > 8 ? setPhnErr(false) : 'fcgc';
                    form.change('phoneNumber', `+${val}`);
                  }}
                  onBlur={() => {
                    values.phoneNumber &&
                    (values.phoneNumber.length > 8 || values.phoneNumber.length < 5)
                      ? setPhnErr(false)
                      : setPhnErr(true);
                  }}
                  placeholder={phoneLabel}
                  enableSearch={true}
                  disableSearchIcon={true}
                />
              </div>
              {phnErr ? (
                <span className={css.errorContacttext}>
                  <FormattedMessage id="signUpForm.phoneRequired" />
                </span>
              ) : (
                ''
              )}
            </div>
            <FieldTextInput
              type="email"
              className={classNames(css.emailandpassword, {
                [css.demoError]: Errorclass,
              })}
              id={formId ? `${formId}.email` : 'email'}
              name="email"
              autoComplete="email"
              label={emailLabel}
              placeholder={emailPlaceholder}
              validate={validators.composeValidators(emailRequired, emailValid)}
            />
            {props.loginOrSignupError}

            <FieldTextInput
              className={classNames(css.password, css.emailandpassword)}
              type="password"
              id={formId ? `${formId}.password` : 'password'}
              name="password"
              autoComplete="new-password"
              label={passwordLabel}
              placeholder={passwordPlaceholder}
              validate={passwordValidators}
            />
          </div>

          <div className={css.bottomWrapper}>
            {/* <p className={css.bottomWrapperText}>
              <span className={css.termsText}>
                <FormattedMessage
                  id="SignupForm.termsAndConditionsAcceptText"
                  values={{ termsLink }}
                />
              </span>
            </p> */}
            <PrimaryButton type="submit" inProgress={submitInProgress} disabled={submitDisabled}>
              <FormattedMessage id="SignupForm.signUp" />
            </PrimaryButton>
          </div>
        </Form>
      );
    }}
  />
);

SignupFormComponent.defaultProps = { inProgress: false };

const { bool, func } = PropTypes;

SignupFormComponent.propTypes = {
  inProgress: bool,

  onOpenTermsOfService: func.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const SignupForm = compose(injectIntl)(SignupFormComponent);
SignupForm.displayName = 'SignupForm';

export default SignupForm;
